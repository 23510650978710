import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Bill Tracker App Template | Bill App UI Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/bill-tracker-mobile-app/"
    metaDescription="Bring your bill tracking app design to life with Utility Tracker, the new bill tracking app UI design template from Uizard. Sign up today."
    description="
    h2:A bill tracking app UI design template for your latest project
    <br/>
    Whether you're a seasoned app designer or new to the game, Uizard’s bill tracker app design template will help you design an app that your users will love. Our bill tracker app design template comes with all the must-have UX considerations baked in, and with Uizard's drag-and-drop editor, you can customize the <a:https://uizard.io/templates/>UX/UI template</a> to fit your specific needs in next to no time!
    <br/>
    h3:Design made easy with the power of AI
    <br/>
    Uizard’s <a:https://uizard.io/ai-design/>AI design</a> features make UX/UI design easier and more accessible than ever before. Use our bill tracker app design template as the launching pad for your design project or take advantage of Uizard’s AI features to import your hand-drawn wireframes. You even import the themes of other apps or websites to completely update the style of your bill tracker app design.
    <br/>
    h3:Design fast, collaborate instantly
    <br/>
    Work collaboratively with your team in real time with Uizard’s smart collaboration features. Add comments to your Uizard project or add new screens in a flash. With Uizard, working with your team or sharing your project with key stakeholders has never been so easy.
    "
    pages={[
      "An intuitive home screen in a minimalist style with soft, mellow colors",
      "An accounts screen mockup with a list of utilities",
      "Notifications screen to demonstrate bill management for your prospective users",
      "Payment tracking screen mockup",
    ]}
    projectCode="XXwl8eP0eouzp0qwOjMM"
    img1={data.image1.childImageSharp}
    img1alt="Bill tracker design summary page"
    img2={data.image2.childImageSharp}
    img2alt="Bill tracker various screens mockup"
    img3={data.image3.childImageSharp}
    img3alt="Bill tracker accounts mockup page"
    img4={data.image4.childImageSharp}
    img4alt="Bill tracker notifications page"
    img5={data.image5.childImageSharp}
    img5alt="Bill tracker payment tracking page"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/bill-tracker-mobile-app/utility-tracker-mobile-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/bill-tracker-mobile-app/utility-tracker-mobile-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/bill-tracker-mobile-app/utility-tracker-mobile-payment-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/bill-tracker-mobile-app/utility-tracker-mobile-notifications.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/bill-tracker-mobile-app/utility-tracker-mobile-payment.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
